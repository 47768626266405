import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    window.onscroll = function() {
      $('.history').each(function() {
        if ($(window).innerWidth() > 991) {
          let elementTop = $(this).offset().top;
          let elementBottom = elementTop + $(this).outerHeight();
          let viewportTop = $(window).scrollTop();
          let viewportBottom = viewportTop + $(window).height();
          let viewportMiddle = viewportTop + ($(window).height() / 2) - 350;

          if ($(this).hasClass('history-first')) {
            if (elementTop < viewportMiddle) {
              $('.history').addClass('hide');
              $(this).removeClass('hide');
              $(this).addClass('fixed');
            } else {
              $(this).removeClass('fixed');
            }
          } else if ($(this).hasClass('history-last')) {
            if ((elementTop - 200) < viewportMiddle) {
              $('.history').addClass('hide');
              $(this).removeClass('hide');
              $(this).addClass('fixed');
            } else {
              $(this).removeClass('fixed');
            }
            if (elementTop < viewportMiddle) {
              $('.history').addClass('hide');
              $(this).removeClass('hide');
              $(this).addClass('absolute');
            } else {
              $(this).removeClass('absolute');
            }
          } else {
            if ((elementTop - 200) < viewportMiddle) {
              $('.history').addClass('hide');
              $(this).removeClass('hide');
              $(this).addClass('fixed');
            } else {
              $(this).removeClass('fixed');
            }
          }
        }
      });
    };
  }

    // $('.history').each(function() {
    //   var elementBottom, elementTop, viewportBottom, viewportMiddle, viewportTop;
    //
    //   if ($(window).innerWidth() > 991) {
    //     elementTop = $(this).offset().top;
    //     elementBottom = elementTop + $(this).outerHeight();
    //     viewportTop = $(window).scrollTop();
    //     viewportBottom = viewportTop + $(window).height();
    //     viewportMiddle = viewportTop + ($(window).height() / 2) - 150;
    //     if ($(this).hasClass('history-first')) {
    //       if (elementTop < viewportMiddle) {
    //         $(this).addClass('fixed');
    //       } else {
    //         $(this).removeClass('fixed');
    //       }
    //     } else if ($(this).hasClass('history-last')) {
    //       if ((elementTop - 200) < viewportMiddle) {
    //         $(this).addClass('fixed');
    //       } else {
    //         $(this).removeClass('fixed');
    //       }
    //       if (elementTop < viewportMiddle) {
    //         $('.history').removeClass('fixed');
    //         $(this).addClass('absolute');
    //       } else {
    //         $(this).removeClass('absolute');
    //       }
    //     } else {
    //       if ((elementTop - 200) < viewportMiddle) {
    //         $(this).addClass('fixed');
    //       } else {
    //         $(this).removeClass('fixed');
    //       }
    //     }
    //   }
    // });
}
