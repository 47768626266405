// Entry point for the build script in your package.json

import "@hotwired/turbo-rails"
import "./add_jquery"
import "jquery.scrollto/jquery.scrollTo"
import "trix"
import "@rails/actiontext"
import "img-comparison-slider/dist/index"

import * as bootstrap from "bootstrap"
import * as datepicker from "bootstrap-datepicker"
require("bootstrap-datepicker/js/locales/bootstrap-datepicker.de.js")

import "./controllers"
