
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {    
    $(window).scroll(function(){
      if ($(document).scrollTop() > 112) {
        $('.navbar-brand').removeClass('extended');
      } else {
        $('.navbar-brand').addClass('extended');
      }
    })


    return false;
  }
}
