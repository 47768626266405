import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    if ($('#map').length > 0) {
      const lat = parseFloat($('#map').data('lat'));
      const lng = parseFloat($('#map').data('lng'));

      const defaultPosition = {
        lat: lat,
        lng: lng
      };

      const map = new google.maps.Map(document.getElementById('map'), {
        mapId: "3608e0839f3bb2a8",
        zoom: 15,
        center: defaultPosition,
        mapTypeControlOptions: {
          mapTypeIds: [],
          mapTypeId: 'satellite'
        },
        streetViewControl: false,
        disableDefaultUI: true
      });

      const markerPosition = new google.maps.LatLng(lat, lng);

      const marker = new google.maps.Marker({
        map: map,
        position: markerPosition,
        draggable: false
      });

      const infowindow = new google.maps.InfoWindow({
        content: $('#map').data('text')
      });

      marker.addListener('click', function() {
        infowindow.open(map, marker);
      });

      google.maps.event.addDomListener(map, 'click', function() {
        infowindow.close();
        infowindow2.close();
        return infowindow3.close();
      });
    }
  }
}
