import { Controller } from "@hotwired/stimulus"

const dataResourceId = "data-resource-id";
const dataParent = "data-parent";
let url;
let resourceId;
let newPosition;

export default class extends Controller {
  connect() {}

  dragStart(event) {
    resourceId = event.target.getAttribute(dataResourceId);
    url = event.target.getAttribute("data-url");
    event.dataTransfer.effectAllowed = "move";
  }

  drop(event) {
    event.preventDefault();
    let parentId = event.target.getAttribute(dataParent);
    const dropTarget = this.findDropTarget(event.target, parentId);
    const draggedItem = document.querySelector(`[data-resource-id="${resourceId}"]`);

    if (dropTarget === null || draggedItem === null) {
      return true;
    }

    this.setNewPosition(dropTarget, draggedItem, event);
    newPosition = [...this.element.parentElement.children].indexOf(draggedItem);
  }

  dragEnd(event) {
    event.preventDefault();

    if (resourceId === null || newPosition === null) {
      return;
    }

    let data = JSON.stringify({
      resource: {
        id: resourceId,
        position: newPosition,
      },
    });

    fetch(url, {
      method: 'POST',
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": this.getMetaValue("csrf-token"),
        "Content-Type": "application/json",
      },
      body: data,
    });
  }

  dragOver(event) {
    event.preventDefault();
    return true;
  }

  dragEnter(event) {
    event.preventDefault();
  }

  findDropTarget(target, parentId) {
    if (target === null) {
      return null;
    }

    if (target.id === parentId) {
      return null;
    }

    if (target.classList.contains("draggable")) {
      return target;
    }

    return this.findDropTarget(target.parentElement, parentId);
  }

  setNewPosition(dropTarget, draggedItem) {
    const positionComparison = dropTarget.compareDocumentPosition(draggedItem);

    if (positionComparison & Node.DOCUMENT_POSITION_FOLLOWING) {
      dropTarget.insertAdjacentElement("beforebegin", draggedItem);
    } else if (positionComparison & Node.DOCUMENT_POSITION_PRECEDING) {
      dropTarget.insertAdjacentElement("afterend", draggedItem);
    }
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`);
    return element.getAttribute("content");
  }
}
