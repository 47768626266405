import { Controller } from "@hotwired/stimulus"

let path;
let layoutStyle;
let url;

export default class extends Controller {

  connect() {}

  change(event) {
    path = event.target.getAttribute("data-url");
    layoutStyle = event.target.value;

    url = path + "?layout_style=" + layoutStyle;

    fetch(url, {
      method: 'GET',
      headers: {
        'X-CSRF-Token': this.getMetaValue("csrf-token"),
        'Content-Type': 'application/json'
      },
      credentials: 'same-origin'
    }).then (response => response.text())
    .then(html => Turbo.renderStreamMessage(html));
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`);
    return element.getAttribute("content");
  }
}
